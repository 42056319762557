// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["nMvDYzhcZ", "uFH111lBK", "eiFsMRRhH"];

const serializationHash = "framer-fvqwU"

const variantClassNames = {eiFsMRRhH: "framer-v-1dq26jw", nMvDYzhcZ: "framer-v-1k2ptp5", uFH111lBK: "framer-v-1jcr7bz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Desktop Small": "uFH111lBK", Desktop: "nMvDYzhcZ", Tablet: "eiFsMRRhH"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "nMvDYzhcZ"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "nMvDYzhcZ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://www.framer.com/marketplace/creator/mejed-alkoutaini/"} openInNewTab><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1k2ptp5", className, classNames)} framer-qp99q8`} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"nMvDYzhcZ"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-a4eee570-1593-4bf5-8c61-0d31b18a9ee5, rgb(245, 245, 245))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, boxShadow: "0px 0.636953px 1.14652px -1.125px rgba(0, 0, 0, 0.26), 0px 1.9316px 3.47689px -2.25px rgba(0, 0, 0, 0.24), 0px 5.10612px 9.19102px -3.375px rgba(0, 0, 0, 0.19), 0px 16px 28.8px -4.5px rgba(0, 0, 0, 0.03)", ...style}} {...addPropertyOverrides({eiFsMRRhH: {"data-framer-name": "Tablet"}, uFH111lBK: {"data-framer-name": "Desktop Small"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UG9wcGlucy02MDA=", "--framer-font-family": "\"Poppins\", \"Poppins Placeholder\", sans-serif", "--framer-font-size": "13px", "--framer-font-weight": "600", "--framer-line-height": "1.4em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-8edfbc8e-7da2-4ff1-8d21-4715f7f1131f, rgb(0, 0, 0)))"}}>More Templates</motion.p></React.Fragment>} className={"framer-1pv92fh"} fonts={["GF;Poppins-600"]} layoutDependency={layoutDependency} layoutId={"SM5oDfIOx"} style={{"--extracted-r6o4lv": "var(--token-8edfbc8e-7da2-4ff1-8d21-4715f7f1131f, rgb(0, 0, 0))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-fvqwU.framer-qp99q8, .framer-fvqwU .framer-qp99q8 { display: block; }", ".framer-fvqwU.framer-1k2ptp5 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 36px; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: 142px; will-change: var(--framer-will-change-override, transform); }", ".framer-fvqwU .framer-1pv92fh { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-fvqwU.framer-1k2ptp5 { gap: 0px; } .framer-fvqwU.framer-1k2ptp5 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-fvqwU.framer-1k2ptp5 > :first-child { margin-top: 0px; } .framer-fvqwU.framer-1k2ptp5 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 142
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"uFH111lBK":{"layout":["fixed","fixed"]},"eiFsMRRhH":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerj15UMoDOs: React.ComponentType<Props> = withCSS(Component, css, "framer-fvqwU") as typeof Component;
export default Framerj15UMoDOs;

Framerj15UMoDOs.displayName = "More Templates";

Framerj15UMoDOs.defaultProps = {height: 36, width: 142};

addPropertyControls(Framerj15UMoDOs, {variant: {options: ["nMvDYzhcZ", "uFH111lBK", "eiFsMRRhH"], optionTitles: ["Desktop", "Desktop Small", "Tablet"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerj15UMoDOs, [{explicitInter: true, fonts: [{family: "Poppins", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLEj6V15vFP-KUEg.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})